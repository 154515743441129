<template>
  <el-dialog
    :visible.sync="visible"
    title="移动到新题库"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
    class="yt-dialog yt-dialog-default"
  >
    <div style="text-align: center;">已选中{{ questionSelection.length }}道题目</div>
    <card style="min-height: 310px;">
      <el-select v-model="moveToBankId" style="width: 100%">
        <el-option v-for="(bank, index) in moveQuestionBankList" :key="index" :value="bank.questionBankId" :label="bank.name"></el-option>
      </el-select>
    </card>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" @click="ok">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'QuestionMoveDialog',
  props: ['moveQuestionBankList'],
  data() {
    return {
      visible: false,
      questionSelection: '',
      moveToBankId: ''
    }
  },
  methods: {
    open(data) {
      this.visible = true
      this.questionSelection = data
    },
    ok() {
      if (this.moveToBankId !== '') {
        this.loading = true
        this.visible = false
        this.$emit('on-success', this.moveToBankId)
      } else {
        this.$message.warning('请选择移动到的题库')
      }
    },
    close(done) {
      done()
    },
    closed() {
      this.moveToBankId = ''
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped></style>
