import config from '../config/config'
import { axios } from './index'

const questionCommentUrl = config.baseUrl + '/question/api/v2/comment'

export default {
  commentsByQuestionId(questionId) {
    // 查询评论
    return axios.get(`${questionCommentUrl}/get/comments?questionId=${questionId}`)
  },
  addCommentByQuestionId(form) {
    // 新增评论
    return axios.post(`${questionCommentUrl}/add`, form)
  },
  deleteCommentByQuestionId(questionCommentId) {
    // 删除评论
    return axios.post(`${questionCommentUrl}/delete?questionCommentId=${questionCommentId}`)
  },
  updateCommentByQuestionId(form) {
    // 修改评论
    return axios.post(`${questionCommentUrl}/update`, form)
  }
}
