<template>
  <el-dialog @close="reset()" class="question__modal" :lock-scroll="true" :show-close="false" :visible.sync="value" width="65%" destroy-on-close>
    <div slot="title" class="title yt-flex-layout">
      <b class="tip">{{ commentCount || 0 }}<span>条评论</span></b>
      <el-button @click="addComment()" :loading="loading" :disabled="!commentVal" class="yt-flex-layout" type="primary">提交评论</el-button>
    </div>
    <div class="content">
      <RichEditor ref="richEditor" class="editor__wang" v-model="commentVal" :iconShow="false" :defaultText="'请输入评论内容'" />
      <el-divider><b class="tip">全部评论</b></el-divider>
      <div class="comment-div">
        <div
          @mouseenter="questionCommentId = c.questionCommentId"
          @mouseleave="questionCommentId = null"
          :class="questionCommentId === c.questionCommentId && c.isOperate ? 'row_one-id' : ''"
          class="row_one"
          v-for="(c, cIndex) in commentList"
          :key="cIndex"
        >
          <div class="header yt-flex-layout" style="justify-content: space-between">
            <span>{{ c.userName }}</span>
            <span>{{ $formatTime(c.modifyTime, 'yyyy-MM-dd') }}</span>
          </div>
          <div v-if="!c.isEditor" class="row_content" v-html="c.content" />
          <RichEditor v-else v-model="commentList[cIndex].content" />
          <div v-show="c.isOperate && questionCommentId === c.questionCommentId" class="option-icon yt-flex-layout">
            <div v-show="!c.isEditor" @click="toEditor(c, cIndex)" class="btn yt-flex-layout">
              <YTIcon @click="toEditor(c, cIndex)" href="#icon-caozuobianji" />
              <span style="margin-left: 3px">编辑</span>
            </div>
            <div v-show="c.isEditor" @click="toSubmit(c, cIndex)" class="btn yt-flex-layout">
              <YTIcon @click="toSubmit(c, cIndex)" href="#icon-baocun" />
              <span style="margin-left: 3px">提交</span>
            </div>
            <div @click="delComment(c, cIndex)" class="btn btn2 yt-flex-layout">
              <YTIcon href="#icon-shanchu" />
              <span style="margin-left: 3px">删除</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import RichEditor from '@components/common/RichEditor'
import YTIcon from '@components/common/YTIcon'
import questionCommentApi from '@api/questionComment'
export default {
  name: 'QuestionCommentsModal',
  components: { YTIcon, RichEditor },
  data() {
    return {
      value: false,
      loading: false,
      commentVal: null, // 新评论中的值
      commentCount: null,
      questionId: null,
      questionCommentId: null, // 判断是否可操作
      commentList: []
    }
  },
  methods: {
    reset() {
      this.commentList = []
      this.commentCount = null
      this.questionId = null
      this.questionCommentId = null
      this.loading = false
    },
    addComment() {
      this.loading = true
      let payload = {
        content: this.commentVal,
        questionId: this.questionId
      }
      questionCommentApi
        .addCommentByQuestionId(payload)
        .then(res => {
          if (res.res && res.code === 0) {
            // 发表成功
            this.$refs['richEditor'].clearEditor()
            this.getAllCommentById(this.questionId)
            this.$parent.searchQuestionBanks()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    open(row) {
      // 打开modal
      this.commentCount = row.commentCount
      this.questionId = row.id
      this.getAllCommentById(row.id)
      this.value = true
    },
    getAllCommentById(id) {
      // 获取该题目下所有评论
      questionCommentApi.commentsByQuestionId(id).then(res => {
        if (res.res.length) {
          this.commentList = res.res
          this.commentCount = res.res.length
        }
      })
    },
    toEditor(row, cIndex) {
      // 编辑评论
      this.$set(this.commentList[cIndex], 'isEditor', true)
    },
    toSubmit(row, cIndex) {
      // 编辑后提交按钮
      let payload = {
        content: row.content,
        questionCommentId: row.questionCommentId
      }
      questionCommentApi.updateCommentByQuestionId(payload).then(res => {
        if (res.res && res.code === 0) {
          // 编辑成功
          this.getAllCommentById(this.questionId)
        }
      })
      this.$set(this.commentList[cIndex], 'isEditor', false)
    },
    delComment(row, cIndex) {
      // 删除某条评论
      questionCommentApi.deleteCommentByQuestionId(row.questionCommentId).then(res => {
        if (res.res && res.code === 0) {
          this.$set(this.commentList[cIndex], 'isEditor', false)
          this.commentList = this.commentList.filter((c, index) => index !== cIndex)
          this.$parent.searchQuestionBanks()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .tip {
    font-size: 20px;
  }
  .title {
    span {
      font-weight: 400;
      font-size: 16px;
    }
    margin-bottom: 14px;
    justify-content: space-between;
    button {
      .wh(80px, 32px);
      justify-content: center;
      align-items: center;
    }
  }
  .content {
    padding-right: 20px;
    .editor__wang {
      margin-bottom: 30px;
    }
    .comment-div {
      padding-top: 50px;
      .row_one-id {
        border: 1px solid #448bff !important;
      }
      .row_one {
        border: 1px solid transparent;
        position: relative;
        padding: 20px 10px;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
        .header {
          margin-bottom: 8px;
          span {
            .font(14px, Regular, #666);
            &:last-child {
              .font(12px, Regular, #ccc);
            }
          }
        }
        .row_content {
          .font(14px, Regular, #000);
        }
        .option-icon {
          position: absolute;
          right: -1px;
          top: -32px;
          .btn {
            cursor: pointer;
            color: #448bff;
            justify-content: center;
            align-items: center;
            .wh(80px, 32px);
            border-left: 1px solid #448bff;
            border-top: 1px solid #448bff;
            &:hover {
              color: #fff;
              background-color: #448bff;
            }
          }
          .btn2 {
            border-right: 1px solid #448bff;
          }
        }
      }
    }
  }
  .el-dialog {
    //position: absolute;
    padding: 0 20px;
    margin: 0 !important;
    //right: 0;
    max-width: 900px;
    min-width: 750px;
  }
  .el-dialog__body {
    padding-right: 0 !important;
    padding-top: 0 !important;
    /*max-height: 700px;*/
    /*min-height: 500px;*/
    overflow-y: auto;
    /*overscroll-behavior: contain;*/
  }
  .el-divider {
    top: 30px;
  }
}
</style>
